import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatNumber(number: number, digits = 2, locale = 'de-DE') {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number)
}

export function cmsProxyUrl<T extends string | undefined | null>(url: T): T {
  if (!url) return url
  if (typeof url === 'string' && url.startsWith('/api')) {
    return url.replace('/api', '/api/cms') as T
  }
  return url
}

// Very basic formatting function for Austrian and Hungarian phone numbers.
// A more robust solution would be to use a library like [libphonenumber](https://www.npmjs.com/package/google-libphonenumber),
// but it may be overkill for this use case and could cause issues with webpack.
export function formatPhoneNumber(phoneNumber: string | undefined | null) {
  if (!phoneNumber) return phoneNumber
  // Remove any non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '')
  if (!cleaned.startsWith('00')) return phoneNumber

  // Check if it's an Austrian number
  if (cleaned.startsWith('0043')) {
    const countryCode = cleaned.slice(2, '0043'.length)
    // Vienna area code is 1, the rest of the country has 4-digit area codes
    const areaCodeLength = cleaned.slice('0043'.length).startsWith('1') ? 1 : 4
    const areaCode = cleaned.slice(
      '0043'.length,
      '0043'.length + areaCodeLength,
    )
    const remainingDigits = cleaned.slice('0043'.length + areaCode.length)

    return `+${countryCode}-${areaCode}-${remainingDigits}`
  }

  // Check if it's a Hungarian number
  if (cleaned.startsWith('0036')) {
    const countryCode = cleaned.slice(2, '0036'.length)
    // Budapest area code is 1, the rest of the country has 2-digit area codes
    const areaCodeLength = cleaned.slice('0036'.length).startsWith('1') ? 1 : 2
    const areaCode = cleaned.slice('0036'.length, areaCodeLength)
    const remainingDigits = cleaned.slice('0036'.length + areaCode.length)

    return `+${countryCode}-${areaCode}-${remainingDigits}`
  }

  // For other countries, split the country code and remaining digits
  const countryCode = cleaned.slice(0, 2)
  const remainingDigits = cleaned.slice(2)

  return `+${countryCode}-${remainingDigits}`
}
export { sanitize } from './sanitize'
